import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { useParams } from "react-router-dom";
import Layout from "../../../common/Layout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./Blog.css";

const SingleBlog = () => {
  const [singleBlogPost, setSingleBlogPost] = useState(null);
  const { id } = useParams();

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

  useEffect(() => {
    const getEntryById = async () => {
      try {
        const entry = await client.getEntry(id);
        setSingleBlogPost(entry);
      } catch (error) {
        console.error("Error: ", error);
      }
    };
    getEntryById();
  }, [id]);

  if (!singleBlogPost) {
    return <div>Loading...</div>;
  }

  const options = {};

  return (
    <Layout>
      <div className="blog-container">
        {/* Header */}
        <header className="blog-header">
          <h1 className="blog-title">{singleBlogPost.fields.postTitle}</h1>
        </header>

        {/* Main Content */}
        <main>
          <img
            src={singleBlogPost.fields.postImage.fields.file.url}
            alt="Blog"
            className="blog-main-image"
          />
          <div className="author-info">
            <img
              src="https://via.placeholder.com/80" // Replace with actual author image if available
              alt="Author"
              className="author-photo"
            />
            <div className="author-read">
              <div>
                <h3 className="author-name">{singleBlogPost.fields.author}</h3>
                <p className="publish-date">
                  {new Intl.DateTimeFormat("en-GB", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  }).format(new Date(singleBlogPost.fields.publishDate))}
                </p>
              </div>
              <span className="read-time">
                Read Time: {singleBlogPost.fields.readTime} Minutes
              </span>
            </div>
          </div>

          <article className="blog-article">
            {typeof singleBlogPost.fields.discription === "object"
              ? documentToReactComponents(
                  singleBlogPost.fields.discription,
                  options
                )
              : singleBlogPost.fields.discription}

            {typeof singleBlogPost.fields.postContent === "object"
              ? documentToReactComponents(
                  singleBlogPost.fields.postContent,
                  options
                )
              : singleBlogPost.fields.postContent}
          </article>
        </main>
      </div>
    </Layout>
  );
};

export default SingleBlog;
