import React from "react";
import { CourseGrid } from "../layouts/course03/index";

import Layout from "../../common/Layout";

const CourseThree = () => {
  return (
    <div className="page">
      <Layout>
        <CourseGrid />
      </Layout>
    </div>
  );
};
export default CourseThree;
