import React from "react";
import { CourseGrid } from "../layouts/course02/index";

import Layout from "../../common/Layout";

const CourseTwo = () => {
  return (
    <div className="page">
      <Layout>
        <CourseGrid />
      </Layout>
    </div>
  );
};
export default CourseTwo;
