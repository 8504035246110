import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./Blog.css";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const styles = {
  container: {
    fontFamily: "'Roboto', sans-serif",
    maxWidth: "1400px",
    margin: "0 auto",
    padding: "20px",
  },
  featuredSection: {
    position: "relative",
    marginBottom: "50px",
  },
  featuredImage: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  featuredContent: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "white",
    padding: "20px",
    borderRadius: "8px",
  },
  blogGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: "20px",
  },
  card: {
    backgroundColor: "white",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  cardImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  },
  cardContent: {
    padding: "15px",
  },
  cardTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
  },
  cardMeta: {
    fontSize: "14px",
    color: "#999",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  filters: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    flexWrap: "wrap",
    gap: "10px",
  },
  filterButton: {
    padding: "10px 20px",
    borderRadius: "20px",
    border: "1px solid #ddd",
    cursor: "pointer",
    backgroundColor: "#fff",
    color: "#333",
    transition: "background-color 0.3s, color 0.3s",
  },
  activeFilter: {
    backgroundColor: "#b22e30",
    color: "white",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
  },
  paginationButton: {
    padding: "10px 15px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    cursor: "pointer",
    margin: "0 5px",
  },
  activePagination: {
    backgroundColor: "#b22e30",
    color: "white",
  },
};

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const entries = await client.getEntries({ content_type: "post" });
        const fetchedPosts = entries.items.map((entry) => ({
          id: entry.sys.id,
          title: entry.fields.postTitle,
          categories: entry.fields.categories || [],
          excerpt:
            entry.fields.discription?.content[0]?.content[0]?.value || "",
          featuredImage: entry.fields.postImage.fields.file.url,
          author: entry.fields.author,
          date: new Intl.DateTimeFormat("en-GB", {
            month: "long",
            day: "2-digit",
            year: "numeric",
          }).format(new Date(entry.fields.publishDate)),
          readTime: entry.fields.readTime || "N/A",
        }));
        setPosts(fetchedPosts);

        // Extract unique categories
        const uniqueCategories = [
          ...new Set(fetchedPosts.flatMap((post) => post.categories)),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error(`Error fetching posts: ${error}`);
      }
    };

    fetchPosts();
  }, []);

  const filteredPosts =
    selectedCategory === "All"
      ? posts
      : posts.filter((post) => post.categories.includes(selectedCategory));

  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  const paginatedPosts = filteredPosts.slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  );

  return (
    <div style={styles.container}>
      {/* Featured Section */}
      {filteredPosts.length > 0 && (
        <div
          className="featured-section"
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            marginBottom: "80px",
          }}
        >
          {/* First Featured Image */}
          <div
            style={{ position: "relative", gridRow: "span 2" }}
            className="feature1"
          >
            <LazyLoadImage
              src={filteredPosts[0].featuredImage}
              alt={filteredPosts[0].title}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <div style={styles.featuredContent}>
              <h1 className="text-white l" style={{ fontSize: "30px" }}>
                {filteredPosts[0].title}
              </h1>
              <p className="text-white">
                {filteredPosts[0].date + " " + filteredPosts[0].author}
              </p>
            </div>
          </div>

          {/* Second Featured Image */}
          {filteredPosts[1] && (
            <div style={{ position: "relative" }} className="featured2">
              <LazyLoadImage
                src={filteredPosts[1].featuredImage}
                alt={filteredPosts[1].title}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <div style={styles.featuredContent}>
                <h2 className="text-white" style={{ fontSize: "26px" }}>
                  {filteredPosts[1].title}
                </h2>
                <p className="text-white">
                  {filteredPosts[1].date + " " + filteredPosts[1].author}
                </p>
              </div>
            </div>
          )}

          {/* Third Featured Image */}
          {filteredPosts[2] && (
            <div style={{ position: "relative" }} className="featured2">
              <LazyLoadImage
                src={filteredPosts[2].featuredImage}
                alt={filteredPosts[2].title}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <div style={styles.featuredContent}>
                <h2 className="text-white" style={{ fontSize: "26px" }}>
                  {filteredPosts[2].title}
                </h2>
                <p className="text-white">
                  {filteredPosts[2].date + " " + filteredPosts[2].author}
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Filters */}
      <div className="container">
        <div style={styles.filters}>
          <button
            style={{
              ...styles.filterButton,
              ...(selectedCategory === "All" ? styles.activeFilter : {}),
            }}
            onClick={() => {
              setSelectedCategory("All");
              setCurrentPage(1);
            }}
          >
            All
          </button>
          {categories.map((category, index) => (
            <button
              key={index}
              style={{
                ...styles.filterButton,
                ...(selectedCategory === category ? styles.activeFilter : {}),
              }}
              onClick={() => {
                setSelectedCategory(category);
                setCurrentPage(1);
              }}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Blog Grid */}
        <div style={styles.blogGrid}>
          {paginatedPosts.map((post) => (
            <div key={post.id} style={styles.card}>
              <Link to={`/SingleBlog/${post.id}`}>
                <LazyLoadImage
                  src={post.featuredImage}
                  alt={post.title}
                  style={styles.cardImage}
                />
                <div style={styles.cardContent}>
                  <h3 style={styles.cardTitle}>{post.title}</h3>

                  <div style={styles.cardMeta}>
                    <span>{post.date}</span>
                    <span>{post.readTime} Minutes</span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div style={styles.pagination}>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              style={{
                ...styles.paginationButton,
                ...(currentPage === i + 1 ? styles.activePagination : {}),
              }}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
