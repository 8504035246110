import React from "react";
import { CourseGrid } from "../layouts/course04/index";

import Layout from "../../common/Layout";

const CourseFour = () => {
  return (
    <div className="page">
      <Layout>
        <CourseGrid />
      </Layout>
    </div>
  );
};
export default CourseFour;
