import React from "react";
import { Counter, Features, Team, Review } from "../layouts/about/index";
import Layout from "../../common/Layout";

const AboutMain = () => {
  return (
    <div className="about">
      <Layout>
        <Features />
        <Team />
        <Counter />
        <Review />
      </Layout>
    </div>
  );
};

export default AboutMain;
