import React from "react";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <>
      <section className="footer ">
        <div className="footer-mid">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-sm-8">
                <div className="footer-logo mb-3">
                  <img
                    src={` ${process.env.PUBLIC_URL}/assets/images/logo-white.png`}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="widget footer-widget mb-5 mb-lg-0">
                  <p>
                    We focus on technologies like Routing & Switching, Cyber
                    Security, Cloud Computing, DevOps, Ethical Hacking, Web
                    Development, Graphic Designing, SEO, Digital Marketing,
                    Operating Systems, etc.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-sm-4">
                <div className="footer-widget mb-5 mb-xl-0 ">
                  <h5 className="widget-title ">Explore</h5>
                  <ul className="list-unstyled footer-links">
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/contact">Support</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-sm-4">
                <div className="footer-widget mb-5 mb-xl-0">
                  <h5 className="widget-title ">Categories</h5>
                  <ul className="list-unstyled footer-links">
                    <li>
                      <Link to="/category/Artificial Intelligence">
                        Artificial Intelligence
                      </Link>
                    </li>
                    <li>
                      <Link to="/category/Network Administration">
                        Network Administration
                      </Link>
                    </li>
                    <li>
                      <Link to="/category/Cloud Computing">
                        Cloud Computing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/category/Cyber Security
"
                      >
                        Cyber Security
                      </Link>
                    </li>
                    <li>
                      <Link to="/category/Web Development">
                        Web Development
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-sm-4">
                <div className="footer-widget mb-5 mb-xl-0">
                  <h5 className="widget-title">Links</h5>
                  <ul className="list-unstyled footer-links">
                    <li>
                      <Link to="#">News & Blogs</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="#">Support</Link>
                    </li>
                    <li>
                      <Link to="#">Return Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <hr></hr>
              <div className="col-xl-12 col-sm-4">
                <div className="row footer-widget mb-5 mb-xl-0">
                  <div className="col-xl-3">
                    <ul className=" list-unstyled footer-links">
                      <li>
                        <Link to="#">
                          <h6 className="text-white">Lahore ( HeadOffice )</h6>
                        </Link>

                        <Link>
                          Address: 11A-D1, Ghalib Road, Gulberg III, Lahore
                        </Link>
                        <br />
                        <Link to="#">Cell: +92 303 8888555</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3">
                    <ul className=" list-unstyled footer-links">
                      <li>
                        <h6 className="text-white">Islamabad </h6>

                        <Link to="#">
                          Address: 70-W, Al-Malik Center First Floor Jinnah
                          Avenue Blue Area Islamabad.
                        </Link>
                        <br />
                        <Link to="#">Tel: (051) 2348287-8</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3">
                    <ul className=" list-unstyled footer-links">
                      <li>
                        <h6 className="text-white">Rawalpindi </h6>

                        <Link to="#">
                          Address: 2nd Floor Zarwar Center, main Murree Road 6th
                          Road stop, Block A Satellite Town, Rawalpindi
                        </Link>
                        <br />
                        <Link to="#">Tel: (051) 4928005-6</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3">
                    <ul className=" list-unstyled footer-links">
                      <li>
                        <h6 className="text-white">Muzaffarabad </h6>

                        <Link to="#">
                          Address: Tech Era College, Bank Road, Muzaffarabad,
                          Muzaffarabad, Pakistan
                        </Link>
                        <br />
                        <Link to="#">Cell: +92 316 9994589</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-socials mt-4">
                    <Link to="#">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-pinterest"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-btm">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-sm-12 col-lg-6">
                <p className="mb-0 copyright text-sm-center text-lg-start">
                  © 2024 Corvit All rights reserved by{" "}
                  <Link to="#" rel="nofollow">
                    Corvit
                  </Link>{" "}
                </p>
              </div>
              <div className="col-xl-6 col-sm-12 col-lg-6">
                <div className="footer-btm-links text-start text-sm-center text-lg-end">
                  <Link to="#">Legal</Link>
                  <Link to="#">Supports</Link>
                  <Link to="#">Terms</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="fixed-btm-top">
          <Link to="#top-header" className="js-scroll-trigger scroll-to-top">
            <i className="fa fa-angle-up"></i>
          </Link>
        </div> */}
      </section>
    </>
  );
};

export default FooterTwo;
