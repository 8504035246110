import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import Layout from "../../common/Layout";
import { Link } from "react-router-dom";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const styles = {
  container: {
    display: "flex",
    minHeight: "100vh",
    maxWidth: "1440px",
    margin: "0 auto",
    gap: "24px",
    padding: "24px",
    backgroundColor: "#f8f8f8",
    fontFamily: "Arial, sans-serif",
  },
  column: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
  header: {
    backgroundColor: "#b22e30",
    color: "white",
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    margin: 0,
    color: "white",
  },

  content: {
    padding: "24px",
  },
  searchContainer: {
    display: "flex",
    gap: "16px",
    marginBottom: "32px",
  },
  searchInput: {
    flex: 1,
    padding: "8px 16px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  itemList: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  item: {
    display: "flex",
    gap: "24px",
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  verticalLine: {
    width: "7px",
    height: "70%",
    backgroundColor: "#b22e30", // Red color
    borderRadius: "4px",
  },
  date: {
    textAlign: "center",
    color: "#b22e30",
    fontWeight: "bold",
  },
  itemContent: {
    flex: 1,
  },
  link: {
    color: "#333",
    textDecoration: "none",
    marginBottom: "4px",
    display: "block",
  },
  fullDate: {
    color: "#666",
    fontSize: "14px",
  },
};

function NewsEventsPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [newsItems, setNewsItems] = useState([]);

  useEffect(() => {
    const fetchNewsItems = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "newsAndEvent",
        });

        const fetchedNewsItems = entries.items.map((entry) => ({
          date: new Date(entry.sys.updatedAt).toLocaleString("default", {
            month: "short",
            day: "2-digit",
          }),
          year: new Date(entry.sys.updatedAt).getFullYear(),
          title: entry.fields.heading,
          fullDate: new Date(entry.sys.updatedAt).toLocaleDateString(),
          id: entry.sys.id,
        }));
        setNewsItems(fetchedNewsItems);
      } catch (error) {
        console.error(`Error fetching news items: ${error}`);
      }
    };

    fetchNewsItems();
  }, []);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = newsItems.filter((item) =>
      item.title.toLowerCase().includes(term.toLowerCase())
    );
    setNewsItems(filtered);
  };

  return (
    <div className="" style={styles.container}>
      <div style={styles.column}>
        <div style={styles.header}>
          <h1 style={styles.title}>News & Events</h1>
        </div>
        <div style={styles.content}>
          <div style={styles.searchContainer}>
            <input
              type="text"
              placeholder="Search for..."
              style={styles.searchInput}
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div style={styles.itemList}>
            {newsItems.map((item, index) => (
              <div key={index} style={styles.item}>
                <div style={styles.dateContainer}>
                  <div style={styles.date}>
                    <div>{item.date}</div>
                    <div>{item.year}</div>
                  </div>
                  <div style={styles.verticalLine}></div>
                </div>

                <div style={styles.itemContent}>
                  <Link to={`/news/${crypto.randomUUID()}/events/${item.id}`}>
                    <button
                      style={{
                        ...styles.link,
                        background: "none",
                        border: "none",
                        padding: 0,
                        cursor: "pointer",
                      }}
                    >
                      {item.title}
                    </button>
                  </Link>
                  <div style={styles.fullDate}>{item.fullDate}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const Announcement = () => {
  return (
    <Layout>
      <NewsEventsPage />
    </Layout>
  );
};

export default Announcement;
