import React from "react";
import { useParams } from "react-router-dom";
import CategoriesData from "../../data/course/CourseData.json"; // Updated import path

import Layout from "../../common/Layout";

import CourseTypeOne from "../layouts/courses/CourseTypeOne";
// data\course\CourseData.json
const CategoryPage = () => {
  const { category } = useParams();
  return (
    <Layout>
      <CategoryOne category={category} />
    </Layout>
  );
};

const CategoryOne = ({ category }) => {
  const filteredCourses = CategoriesData.filter(
    (course) => course.categorieSection === category
  );
  return (
    <div className="container">
      <div className="  page-wrapper">
        <p>Total Courses:{filteredCourses.length}</p>
        {filteredCourses.length > 0 ? (
          filteredCourses.map((course) => <CourseTypeOne data={course} />)
        ) : (
          <>
            <div className="col-12">
              <h1>No courses available for this category.</h1>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;
