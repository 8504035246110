import React from "react";
import Layout from "../../common/Layout";

import { CourseTable } from "../layouts/schedule";

const CategoryPage = () => {
  return (
    <Layout>
      <div className=" container-grid ">
        <CourseTable />
      </div>
    </Layout>
  );
};

export default CategoryPage;
