import React from "react";
import { CourseGrid } from "../layouts/courseList/index";

import Layout from "../../common/Layout";

const CourseList = () => {
  return (
    <div className="course-page">
      <Layout>
        <CourseGrid />
      </Layout>
    </div>
  );
};

export default CourseList;
