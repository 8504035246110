import React from "react";
import Layout from "../../common/Layout";

import { ContactInfo } from "../layouts/contact/index";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <Layout>
        <ContactInfo />
      </Layout>
    </div>
  );
};

export default ContactPage;
