import React from "react";
import Layout from "../../common/Layout";

import { BlogList } from "../layouts/blog";
const Blog = () => {
  return (
    <Layout>
      <BlogList />
    </Layout>
  );
};

export default Blog;
