import React, { Component } from "react";
import CategoriesType from "./CategoriesType";
// import CourseData from "../../../data/course/CourseData.json";
import CategoriesData from "../../../data/cate/CategoriesData.json";
class CategoriesGrid extends Component {
  render() {
    return (
      <>
        <section>
          <div className="row justify-content-center">
            <div className="row justify-content-center">
              <div className="col-xl-6 ">
                <div className="section-heading text-center mb-30">
                  <span className="subheading">YOUR GATEWAY TO EVERYTHING</span>
                  <h2 className="font-lg">Discover Our Range</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row ">
              {CategoriesData.map((item) => (
                <div className="col-xl-4 col-lg-6 col-md-6" key={item.id}>
                  <CategoriesType data={item} />
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default CategoriesGrid;
