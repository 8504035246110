import React from "react";
import { InstructorGrid } from "../layouts/instructors/index";

import Layout from "../../common/Layout";

const InstructorOne = () => {
  return (
    <div className="page">
      <Layout>
        <InstructorGrid />
      </Layout>
    </div>
  );
};

export default InstructorOne;
