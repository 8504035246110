import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import { Bell } from "lucide-react";

const HeaderTwo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => {
    setIsMenuOpen(true);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <header className="header-style-1" id="top-header">
        <div className="header-topbar topbar-style-1">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-8 col-lg-8 col-sm-6">
                <div className="header-contact text-center text-sm-start text-lg-start">
                  <Link to="#">
                    1st Floor, Ali Tower, University Road, Peshawar | (091) 570
                    1670-1
                  </Link>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="header-socials text-center text-lg-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <Link
                        to="https://www.facebook.com/Corvit.psh"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="https://wa.me/3169994595" target="_blank">
                        <i
                          className="fab fa-whatsapp"
                          style={{ fontSize: 16, fontWeight: "bold" }}
                        ></i>
                      </Link>
                    </li>

                    <li className="list-inline-item">
                      <Link
                        to="https://www.linkedin.com/company/corvitnetworks/mycompany/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>

                    <li className="list-inline-item">
                      <Link
                        to="https://youtube.com/@corvitsystemslahore?si=5AJa4BbBwc6N7z2_"
                        target="_blank"
                      >
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`header-navbar navbar-sticky ${
            windowWidth <= 991 ? "mobile-menu" : ""
          }`}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="site-logo">
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                    alt="Course Thumb"
                    className="img-fluid"
                  />
                </Link>
              </div>

              <div className="offcanvas-icon d-block d-lg-none ">
                <Link to="/news-events">
                  <Bell
                    color="black"
                    className="bell-icon"
                    style={{ margin: "20px" }}
                  />
                </Link>
                <button
                  onClick={openMenu}
                  className="nav-toggler"
                  style={{ background: "black", border: "none", padding: 0 }}
                >
                  <i className="fal fa-bars"></i>
                </button>
              </div>

              <nav
                className={`site-navbar ms-auto ${isMenuOpen ? "menu-on" : ""}`}
              >
                {isMenuOpen && (
                  <button
                    onClick={closeMenu}
                    className="nav-close"
                    style={{
                      background: "white",
                      border: "none",
                      padding: " 0 20px",
                      // color: "white",
                      position: "absolute",
                      top: 40,
                      right: 20,
                      color: "black",
                    }}
                  >
                    <i className="fal fa-times"></i>
                  </button>
                )}
                <Nav />
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderTwo;
