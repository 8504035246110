import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const fetchCourses = async () => {
  const response = await client.getEntries({ content_type: "classSchedule" });
  return response.items.map((item) => {
    // Convert startDate ISO string to Date object
    const date = new Date(item.fields.startDate);

    // Format date as dd-MMM-yyyy
    const day = date.getDate().toString().padStart(2, "0");
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase();
    const year = date.getFullYear();

    return {
      id: item.sys.id,
      name: `${item.fields.courseName} - ${item.fields.dayTime}`,
      field: item.fields.field,
      mode: item.fields.mode,
      trainer: item.fields.trainer || "N/A",
      day: item.fields.day,
      startDate: `${day}-${month}-${year}`,
      timing: `${item.fields.classStartingTime} - ${item.fields.classEndingTime}`,
      branch: item.fields.branch,
    };
  });
};
