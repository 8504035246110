import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../../common/Layout";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const styles = {
  container: {
    minHeight: "100vh",
    maxWidth: "1440px",
    margin: "0 auto",
    gap: "24px",
    padding: "24px",
    backgroundColor: "#f8f8f8",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    backgroundColor: "#b22e30",
    color: "white",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px  8px 0 0",
  },
  headerTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    margin: 0,
    color: "white",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    cursor: "pointer",
  },
  card: {
    backgroundColor: "white",
    borderRadius: "0  0 8px 8px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    padding: "24px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  cardHeaderTitle: {
    fontSize: "20px",
    fontWeight: "600",
  },
  cardHeaderDate: {
    fontSize: "14px",
    color: "#666",
  },
  cardContent: {
    fontSize: "16px",
    lineHeight: "1.6",
    color: "#4a5568",
  },
};

function NewsDetail() {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);

  useEffect(() => {
    const fetchNewsItem = async () => {
      try {
        const entry = await client.getEntry(id);
        setNewsItem({
          title: entry.fields.heading,
          date: new Date(entry.sys.createdAt).toLocaleDateString(),
          content: entry.fields.discription,
        });
      } catch (error) {
        console.error(`Error fetching news item: ${error}`);
      }
    };

    fetchNewsItem();
  }, [id]);

  if (!newsItem) {
    return <div>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      {/* Header */}
      <header style={styles.header}>
        <h1 style={styles.headerTitle}>News Detail</h1>
        <Link to="/news-events">
          <button style={styles.backButton}>Back</button>
        </Link>
      </header>

      {/* Content */}
      <main>
        <div style={styles.card}>
          <div style={styles.cardHeader}>
            {/* Title and Date */}
            <h2 style={styles.cardHeaderTitle}>{newsItem.title}</h2>
            <div style={styles.cardHeaderDate}>
              Published On: {newsItem.date}
            </div>
          </div>

          {/* Content */}
          <div style={styles.cardContent}>
            {documentToReactComponents(newsItem.content)}
          </div>
        </div>
      </main>
    </div>
  );
}

const AnnouncementDetail = () => {
  return (
    <Layout>
      <NewsDetail />
    </Layout>
  );
};

export default AnnouncementDetail;
