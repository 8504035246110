import React, { Component } from "react";
import { Link } from "react-router-dom";

class About extends Component {
  render() {
    return (
      <section className="be-instructor section-padding-btm">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <img
                src="assets/images/banner/illustration.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-xl-6 col-lg-6">
              <div className="section-heading mt-4 mt-lg-0">
                <span className="subheading">Best IT Training Institute</span>
                <h2 className="font-lg mb-20">Corvit Systems Peshawar </h2>
                <p className="mb-20">
                  For the last 23 years, the acronym “Corvit” has commanded
                  veneration not only in Pakistan the perfectionist in Computer
                  Network Education but also throughout the subcontinent.
                  Corvit, the best IT training institute in Peshawar, is
                  committed to the cause of providing excellent technical
                  education standards through the modern forms of instruction.
                </p>
                <p className="mb-30">
                  For the last two decade, our production of highly skilled
                  professionals has proven our commitment and dedication
                  regarding Computer Networks' literacy.
                </p>

                <Link to="#" className="btn btn-main rounded">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
