import React from "react";
import { CourseGrid } from "../layouts/courses/index";

import Layout from "../../common/Layout";

const CourseOne = () => {
  return (
    <div className="page">
      <Layout>
        <CourseGrid />
      </Layout>
    </div>
  );
};

export default CourseOne;
