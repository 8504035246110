import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../../common/Layout";
import styles from "./FormPage.module.css";
import Swal from "sweetalert2";

const FormPage = () => {
  const location = useLocation();
  const { course } = location.state;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = document.forms["submit-form"];
    fetch(process.env.REACT_APP_GOOGLE_FORM_URL, {
      method: "POST",
      body: new FormData(form),
    })
      .then((response) => {
        setIsSubmitted(true);
        setIsLoading(false);
      })
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Youre Query Submitted Successfully!👋",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          window.location = "/classschedule";
        });
      })

      .catch((error) => {
        console.error("Error!", error.message);
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <div className="contact section-padding ">
        <div className="container">
          <div className={styles.courseDetails}>
            <p className={styles.courseDetail}>
              <strong>Course Name:</strong> {course.name}
            </p>
            <p className={styles.courseDetail}>
              <strong>Mode of Training:</strong> {course.mode}
            </p>
            <p className={styles.courseDetail}>
              <strong>Trainer:</strong> {course.trainer}
            </p>
            <p className={styles.courseDetail}>
              <strong>Day:</strong> {course.day}
            </p>
            <p className={styles.courseDetail}>
              <strong>Starting Date:</strong> {course.startDate}
            </p>
            <p className={styles.courseDetail}>
              <strong>Timing:</strong> {course.timing}
            </p>
            <p className={styles.courseDetail}>
              <strong>Branch:</strong> {course.branch}
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            name="submit-form"
            className={styles.form}
          >
            <div
              className="form-group"
              style={{ display: isLoading ? "none" : "block" }}
            >
              <input
                type="text"
                name="name"
                className="form-control"
                required
                placeholder="Your Name"
              />
            </div>
            <div
              className="form-group"
              style={{ display: isLoading ? "none" : "block" }}
            >
              <input
                type="email"
                name="email"
                required
                className="form-control"
                placeholder="Email Address"
              />
            </div>
            <div
              className="form-group"
              style={{ display: isLoading ? "none" : "block" }}
            >
              <input
                type="tel"
                name="phone"
                required
                className="form-control"
                placeholder="03xxxxxxx"
                pattern="\[0-9]{10}"
                maxLength={11}
                minLength={11}
              />
            </div>
            {/* Hidden inputs for course data */}
            <input type="hidden" name="courseName" value={course.name} />
            <input type="hidden" name="modeOfTraining" value={course.mode} />
            <input type="hidden" name="trainer" value={course.trainer} />
            <input type="hidden" name="day" value={course.day} />
            <input type="hidden" name="startingDate" value={course.startDate} />
            <input type="hidden" name="timing" value={course.timing} />
            <input type="hidden" name="branch" value={course.branch} />
            {isSubmitted ? (
              <h1 className={styles.statusMessage}>Will Contact You Soon👋</h1>
            ) : isLoading ? (
              <h1 className={styles.statusMessage}>Sending Your Query ✈️</h1>
            ) : (
              <button
                type="submit"
                className="btn btn-main w-100 rounded"
                style={{ backgroundColor: "#9a2628", border: "none" }}
              >
                Submit
              </button>
            )}
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default FormPage;
